<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2" @click="informationnachdertxzuinformation()">
            <img src="../assets/icons/vector_back.svg">
            <!-- wenn kein Icon, img entfernen -->
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Nach der TX</p>
          </div>
          <div class="col-2 col-right">
            <!-- wenn kein Icon, img entfernen -->
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-body margintop140 container marginbottom20">
      <p class="pregular fontcolor-white opacity80">Nach der TX tragen Sie eine hohe Eigenverantwortung für Ihre neue Niere. Bitte beachten Sie folgendes:</p>
    </div>

    <div class="container paddingbottom110">
      <div class="accordion" role="tablist">
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion1 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion1 }}
              </p>
            </b-card-body>
          </b-collapse>
          <div></div>
        </b-card>
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion2 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion2 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion3 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion3 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion4 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion4 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion5 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion5 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion6 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion6 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-7 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion7 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion7 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-8 variant="info">
              <p class="psemibold fontcolor-white">
                {{ titleaccordion8 }}
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                {{ textaccordion8 }}
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- <information-accordion accordiontitle="Vitalwerte" accordiontext="Loremipsumdolorsit"></information-accordion> -->
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import InformationAccordionVue from "../components/InformationAccordion.vue";
  import FootNavigationVue from "../components/FootNavigation.vue";
  import router from '../router';

  export default {
    name: 'TestAccordion',
    components: {
      'information-accordion' :InformationAccordionVue,
      'foot-navigation' :FootNavigationVue,
    },
    methods: {
      informationnachdertxzuinformation() {
        router.push({ path: '/information' })
      },
    },
    data() {
      return {
        titleaccordion1:`Belastung`,
        textaccordion1: `
        Ab wann kann ich mich wieder normal belasten? Im Vergleich zu Dialysepatienten zeigen Nierentransplantierte ein viel höheres körperliches und seelisches Wohlbefinden. Die Phase der Anpassung an die neue Lebenssituation dauert etwa sechs Monate bis zwei Jahre, danach ist die Lebensqualität vergleichbar mit der von anderen gesunden Menschen.
        `,
        titleaccordion2:`Sport`,
        textaccordion2: `
        Sport darf grundsätzlich getrieben werden. Wünschenswert sind Ausdauersportarten wie Wandern, Radfahren, Gymnastik oder Schwimmen. Von Sportarten, die das Transplantat gefährden (z.B. durch mögliche Bauchtritte), ist jedoch abzuraten. Dazu gehören Kampfsportarten, Fußball und einige Mannschaftssportarten.
        `,
        titleaccordion3:`Alkohol`,
        textaccordion3: `
        Bei normaler Leberfunktion ist gegen geringe Alkoholmengen nichts einzuwenden. Regelmäßiger Alkoholverzehr ist jedoch zu unterlassen.
        `,
        titleaccordion4:`Rauchen`,
        textaccordion4: `
        Rauchen ist gesundheitsschädlich und sollte unbedingt vermieden werden.
        `,
        titleaccordion5:`Sex`,
        textaccordion5: `
          Nach der Transplantation kann ein normales Sexualleben geführt werden, sobald Sie das Bedürfnis wieder verspüren und die Schmerzen im Operationsgebiet dies zulassen.
         
          Frauen: Die Regelblutung wird sich nach anfänglicher Unregelmäßigkeit wieder normalisieren, d. h. Sie können schwanger werden. Über Möglichkeiten zur Schwangerschaftsverhütung sprechen Sie bitte mit Ihrem Arzt. Im ersten Jahr nach der Transplantation sollten Sie nicht schwanger werden. Eine Schwangerschaft im späteren Transplantationsverlauf ist durchaus möglich, sollte aber mit dem Transplantationszentrum besprochen werden, damit keine Gefährdung von Kind und Transplantat auftritt. In der Regel muss die immunsuppressive Medikation in der Schwangerschaft umgestellt werden. Stillen sollten Sie aufgrund der Immunsuppressiva nicht.

          Männer: Nach der Transplantation wird die Zeugungsfähigkeit meist wieder hergestellt. Nach etwa einem Jahr spricht nichts gegen eine Vaterschaft. Besprechen Sie dies mit Ihrem Arzt..
        `,
        titleaccordion6:`Job`,
        textaccordion6: `
        Nach einer Erholungsphase spricht nichts gegen eine Arbeitsaufnahme. Schwere körperliche Arbeit sollten Sie mit Ihrem Arzt besprechen. Generell sollten Sie keiner Tätigkeit nachgehen, bei der Sie häufig im Kalten oder Nassen arbeiten müssen. Für Tätigkeiten, bei denen Sie viel in Kontakt mit anderen Menschen kommen, gelten die üblichen Vorsichtsmaßnahmen.
        `,
        titleaccordion7:`Verreisen`,
        textaccordion7: `
        Das Verreisen ist eine der wesentlichen wiedergewonnenen Freiheiten nach der Transplantation. Allerdings sollten Sie keine Reiseziele in Länder mit mangelnder Hygiene oder hoher Anfälligkeit für Infektionskrankheiten, wie Malariagebiete, wählen. Sollten Sie in Länder reisen, in denen spezielle Impfungen erforderlich sind, müssen Sie sich rechtzeitig von Ihrem Arzt beraten lassen. Wichtig ist, dass Sie eine ausreichende Menge an Medikamenten für die gesamte Reisedauer mitnehmen. Meiden Sie auf Reisen außerdem zu viel direkte Sonneneinstrahlung. Für das Essen gilt die alte „Traveller-Regel“: „cook it, peel it or forget it“ = was du nicht kochen oder schälen kannst, das esse nicht. Eine auf Wunsch von Ihrem Nephrologen ausgestellte Bescheinigung (möglichst in englischer Sprache) über die notwendige höhere Menge an mitzuführenden Medikamenten sollte jederzeit auf Nachfrage am Zoll, an Flughäfen u. a. vorgezeigt werden können.
        `,
        titleaccordion8:`Autofahren`,
        textaccordion8: `
        Falls keine Einschränkungen vorliegen, können Sie Auto fahren. Dies sollten Sie vorher mit Ihrem zuständigen Straßenverkehrsamt abklären sowie eine entsprechende Bescheinigung von einer Fahrschule einholen.
        `,
      }
    }
   }; 
</script>

<style>
.accordion {
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    text-align: left;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem!important;
}

.p-1 {
    padding: 0rem!important;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-radius: 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card-header:first-child {
    border-radius: 0 !important;
}

.card-header {
    padding: 0.75rem 0rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.btn {
    background: transparent;
    border: 0;
    outline: none;
    border-radius: 30px;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.3rem 0rem;
    font-size: 1rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-info {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-info.focus, .btn-info:focus {
    box-shadow: none !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0rem 1.5rem 0rem;
}

</style>